import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  CREATE_CREDIT,
  COPY_CREDIT,
  DELETE_CREDIT,
  GET_CREDIT_BY_ID,
  GET_CREDITS,
  UPDATE_CREDIT,
  INIT_SELECTED_CREDIT,
} from '../../actions/expense/transactionCredit'

const EMPTY_CREDIT = {
  id: '',
  contact_id: '',
  payment_date: '',
  mailing_address: '',
  ref_no: '',
  tags: '',
  total: '',
  memo: '',
  categories: [],
  items: [],
  addresses: {
    billing: null,
    location: null,
    shipping: null,
  },
}

const DEFAULT_CREDIT = {
  id: '',
  contact_id: '',
  payment_date: '',
  mailing_address: '',
  ref_no: '',
  tags: '',
  total: '',
  memo: '',
  categories: [],
  items: [],
  addresses: {
    billing: null,
    location: null,
    shipping: null,
  },
}

const CREATE_REQUEST_CREDIT = {
  contact_id: '',
  payment_date: '',
  mailing_address: '',
  ref_no: '',
  payee: '',
  tags: '',
  total: '',
  memo: '',
  transaction_list: [],
  transaction_tag: [],
  attachment: [],
  addresses: {
    billing: null,
    location: null,
    shipping: null,
  },
}

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  credits: [],
  emptyCredit: EMPTY_CREDIT,
  selectedCredit: DEFAULT_CREDIT,
  createRequestCredit: CREATE_REQUEST_CREDIT,
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setCredits = (data) => (state) => state.setIn(['credits'], data)

const setEmptyCredit = (data) => (state) => state.setIn(['emptyCredit'], data)

const setSelectedCredit = (data) => (state) => state.setIn(['selectedCredit'], data)

const setCreateRequestCredit = (data) => (state) => state.setIn(['createRequestCredit'], data)

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)

const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)

const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)

const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)

function transactionCreditReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_CREDITS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_CREDITS.SUCCESS:
      return mutate([resetStatus(), setCredits(action.payload)])
    case GET_CREDITS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setCredits([])])
    case CREATE_CREDIT.DO:
      return mutate([
        resetStatusSetSaving(),
        setSelectedCredit(DEFAULT_CREDIT),
        setEmptyCredit(EMPTY_CREDIT),
      ])
    case CREATE_CREDIT.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_CREDIT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case COPY_CREDIT.DO:
      return mutate([resetStatusSetSaving()])
    case COPY_CREDIT.SUCCESS:
      return mutate([resetStatus()])
    case COPY_CREDIT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_CREDIT.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_CREDIT.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_CREDIT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_CREDIT.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_CREDIT.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_CREDIT.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case GET_CREDIT_BY_ID.DO:
      return mutate([resetStatusSetLoading()])
    case GET_CREDIT_BY_ID.SUCCESS:
      return mutate([resetStatus(), setSelectedCredit(action.payload)])
    case GET_CREDIT_BY_ID.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setSelectedCredit(DEFAULT_CREDIT)])
    case INIT_SELECTED_CREDIT:
      return mutate([
        resetStatus(),
        setCreateRequestCredit(CREATE_REQUEST_CREDIT),
        setSelectedCredit(DEFAULT_CREDIT),
        setEmptyCredit(EMPTY_CREDIT),
      ])
    default:
      return state
  }
}

export default transactionCreditReducer
