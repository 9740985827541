import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { destroy, get, post, put, download, printPdf } from '../../../utils/request'
import { navigateToPage } from '../../../utils/route'
import { getInvoicesData } from '../../selectors/sales/salesInvoice'

export const CREATE_INVOICE = createTypes('ZOOM/SALES/CREATE_INVOICE')
export const GET_INVOICES = createTypes('ZOOM/SALES/GET_INVOICES')
export const GET_INVOICE_BY_ID = createTypes('ZOOM/SALES/GET_INVOICE_BY_ID')
export const UPDATE_INVOICE = createTypes('ZOOM/SALES/UPDATE_INVOICE')
export const DELETE_INVOICE = createTypes('ZOOM/SALES/DELETE_INVOICE')
export const VOID_INVOICE = createTypes('ZOOM/SALES/VOID_INVOICE')
export const GET_INVOICE_COUNT = createTypes('ZOOM/SALES/GET_INVOICE_COUNT')
export const GET_INVOICE_TERMS = createTypes('ZOOM/SALES/GET_INVOICE_TERMS')
export const INIT_SELECTED_INVOICE = 'ZOOM/SALES/INIT_SELECTED_INVOICE'
export const GET_LATEST_INVOICE_NUMBER = createTypes('ZOOM/SALES/GET_LATEST_INVOICE_NUMBER')
export const SET_INVOICE_LINES = 'ZOOM/SALES/SET_INVOICE_LINES'
export const SET_INVOICE_INSTALLMENTS = 'ZOOM/SALES/SET_INVOICE_INSTALLMENTS'
export const SET_CURRENT_PAGE = 'ZOOM/SALES/SET_INVOICE_CURRENT_PAGE'
export const SET_SIZE_PER_PAGE = 'ZOOM/SALES/SET_INVOICE_SIZE_PER_PAGE'
export const SET_TOTAL_SIZE = 'ZOOM/SALES/SET_INVOICE_TOTAL_SIZE'
export const setSizePerPage = (params) => createAction(SET_SIZE_PER_PAGE, { payload: params })
export const setCurrentPage = (params) => createAction(SET_CURRENT_PAGE, { payload: params })
export const setTotalSize = (params) => createAction(SET_TOTAL_SIZE, { payload: params })
export const CREATE_RECEIVEPAYMENT = createTypes('ZOOM/SALES/CREATE_RECEIVEPAYMENT')
export const GET_SALES_CHART = createTypes('ZOOM/SALES/GET_SALES_CHART')
export const GET_CUSTOMER_LEDGER = createTypes('ZOOM/SALES/GET_CUSTOMER_LEDGER')

const createInvoiceAction = {
  do: (params) => createAction(CREATE_INVOICE.DO, params),
  success: () => createAction(CREATE_INVOICE.SUCCESS),
  failed: (errors) => createAction(CREATE_INVOICE.FAILED, { errors }),
}
const updateInvoiceAction = {
  do: (params) => createAction(UPDATE_INVOICE.DO, params),
  success: () => createAction(UPDATE_INVOICE.SUCCESS),
  failed: (errors) => createAction(UPDATE_INVOICE.FAILED, { errors }),
}
const getInvoicesAction = {
  do: (params) => createAction(GET_INVOICES.DO, params),
  success: (invoices) => createAction(GET_INVOICES.SUCCESS, { payload: invoices }),
  failed: (errors) => createAction(GET_INVOICES.FAILED, { payload: errors }),
}
const getCustomerLedgerAction = {
  do: (params) => createAction(GET_CUSTOMER_LEDGER.DO, params),
  success: (invoices) => createAction(GET_CUSTOMER_LEDGER.SUCCESS, { payload: invoices }),
  failed: (errors) => createAction(GET_CUSTOMER_LEDGER.FAILED, { payload: errors }),
}
const getInvoiceByIdAction = {
  do: (params) => createAction(GET_INVOICE_BY_ID.DO, params),
  success: (data) => createAction(GET_INVOICE_BY_ID.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_INVOICE_BY_ID.FAILED, { errors }),
}
const deleteInvoiceAction = {
  do: () => createAction(DELETE_INVOICE.DO),
  success: () => createAction(DELETE_INVOICE.SUCCESS),
  failed: (errors) => createAction(DELETE_INVOICE.FAILED, { errors }),
}

const voidInvoiceAction = {
  do: () => createAction(VOID_INVOICE.DO),
  success: () => createAction(VOID_INVOICE.SUCCESS),
  failed: (errors) => createAction(VOID_INVOICE.FAILED, { errors }),
}
const getInvoiceTermsAction = {
  do: () => createAction(GET_INVOICE_TERMS.DO),
  success: (terms) => createAction(GET_INVOICE_TERMS.SUCCESS, { payload: terms }),
  failed: (errors) => createAction(GET_INVOICE_TERMS.FAILED, { errors }),
}
const getInvoiceCountAction = {
  do: () => createAction(GET_INVOICE_COUNT.DO),
  success: (count) => createAction(GET_INVOICE_COUNT.SUCCESS, { payload: count }),
  failed: (errors) => createAction(GET_INVOICE_COUNT.FAILED, { errors }),
}
const getSalesChartAction = {
  do: () => createAction(GET_SALES_CHART.DO),
  success: (count) => createAction(GET_SALES_CHART.SUCCESS, { payload: count }),
  failed: (errors) => createAction(GET_SALES_CHART.FAILED, { errors }),
}
const getLatestInvoiceNumberAction = {
  do: () => createAction(GET_LATEST_INVOICE_NUMBER.DO),
  success: (invoiceNumber) =>
    createAction(GET_LATEST_INVOICE_NUMBER.SUCCESS, { payload: invoiceNumber }),
  failed: (errors) => createAction(GET_LATEST_INVOICE_NUMBER.FAILED, { errors }),
}
const createReceivePaymentAction = {
  do: (params) => createAction(CREATE_RECEIVEPAYMENT.DO, params),
  success: (data) => createAction(CREATE_RECEIVEPAYMENT.SUCCESS, { payload: data }),
  failed: (errors) => createAction(CREATE_RECEIVEPAYMENT.FAILED, { errors }),
}

const getInvoices =
  (pageStart = 1, pageLength = 10, filter = {}) =>
  async (dispatch) => {
    try {
      dispatch(setCurrentPage(pageStart))
      dispatch(setSizePerPage(pageLength))
      dispatch(getInvoicesAction.do())
      const filterStr = JSON.stringify(filter)
      const resp = await get(
        'sales',
        `invoices?page=${pageStart}&page_length=${pageLength}&search=${filterStr}`,
        'GET',
      )
      dispatch(setTotalSize(resp.total))
      dispatch(getInvoicesAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting invoices.'
      dispatch(getInvoicesAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const getCustomerLedger =
  (pageStart = 1, pageLength = 10, id) =>
  async (dispatch) => {
    try {
      dispatch(setCurrentPage(pageStart))
      dispatch(setSizePerPage(pageLength))
      dispatch(getCustomerLedgerAction.do())
      const resp = await get(
        'sales',
        `contacts/${id}/transactions?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(setTotalSize(resp.total))
      dispatch(getCustomerLedgerAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting invoices.'
      dispatch(getCustomerLedgerAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const getInvoiceById = (id) => async (dispatch) => {
  try {
    dispatch(getInvoiceByIdAction.do())
    if (id) {
      let resp = await get('sales', `invoices/${id}`, 'GET')
      let addresses = {}
      resp.addresses.forEach((address) => {
        addresses[address.type] = address
      })
      let invLines = []
      resp.lines.map(async (line) => {
        invLines.push({
          ...line,
          is_taxable: line.taxRates?.is_taxable,
          taxa_on_product: line.taxRates?.taxa_on_product,
          tax_rates: line.taxRates?.tax_rates,
          toolTipDesc: line.taxRates?.desc,
        })
      })
      dispatch(getInvoiceByIdAction.success({ ...resp, addresses: addresses, lines: invLines }))
      return { ...resp, addresses: addresses, lines: invLines }
    } else {
      await dispatch(createAction(INIT_SELECTED_INVOICE))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting invoice data.'
    dispatch(getInvoiceByIdAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}
const createInvoice = (params) => async (dispatch) => {
  try {
    dispatch(createInvoiceAction.do())
    const resp = await post('sales', `invoices`, params)
    dispatch(createInvoiceAction.success(resp))
    getCountDetails()
    dispatch(showSuccessToastAction('Successfully created.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating invoice.'
    dispatch(createInvoiceAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const updateInvoice = (id, params) => async (dispatch) => {
  try {
    dispatch(updateInvoiceAction.do())
    const resp = await put('sales', `invoices/${id}`, params)
    dispatch(showSuccessToastAction('Successfully updated.'))
    dispatch(updateInvoiceAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating invoice.'
    dispatch(updateInvoiceAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const deleteInvoice = (id) => async (dispatch, getState) => {
  try {
    dispatch(deleteInvoiceAction.do())
    const resp = await destroy('sales', `invoices/${id}`)
    const newInvoices = [...getInvoicesData(getState())]
    const index = newInvoices.findIndex((invoice) => invoice.id === id)
    newInvoices.splice(index, 1)
    dispatch(getInvoicesAction.success(newInvoices))
    dispatch(showSuccessToastAction('Successfully deleted.'))
    dispatch(deleteInvoiceAction.success(resp))
    return true
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating invoice.'
    dispatch(deleteInvoiceAction.failed(error))
    dispatch(showErrorToastAction(error))
    return false
  }
}

const voidInvoice = (id) => async (dispatch) => {
  try {
    dispatch(voidInvoiceAction.do())
    const resp = await put('sales', `invoices/void/${id}`)
    dispatch(voidInvoiceAction.success(resp))
    return true
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred.'
    dispatch(voidInvoiceAction.failed(error))
    dispatch(showErrorToastAction(error))
    return false
  }
}
const getInvoiceTerms =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      dispatch(getInvoiceTermsAction.do())
      const resp = await get('sales', `terms?page=${pageStart}&page_length=${pageLength}`, 'GET')
      dispatch(getInvoiceTermsAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting terms.'
      dispatch(getInvoiceTermsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const setInvoiceTerms = (params) => async (dispatch) => {
  try {
    const resp = await post('sales', 'terms', params)
    dispatch(showSuccessToastAction('Successfully created.'))
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating terms.'
    dispatch(showErrorToastAction(error))
  }
}

const getLatestInvoiceNumber = () => async (dispatch) => {
  try {
    dispatch(getLatestInvoiceNumberAction.do())
    const resp = await get('sales', 'invoices/get-invoice-number', 'GET')
    dispatch(getLatestInvoiceNumberAction.success(resp.data))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting latest invoice number.'
    dispatch(getLatestInvoiceNumberAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const getInvoicePDFUrl = async (id) => async (dispatch) => {
  try {
    const resp = await download('sales', `invoices/${id}/get-pdf`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting invoice pdf.'
    return dispatch(showErrorToastAction(error))
  }
}

const getprintDoc = async (id) => async (dispatch) => {
  try {
    const resp = await printPdf('sales', `invoices/${id}/get-pdf`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting invoice pdf.'
    return dispatch(showErrorToastAction(error))
  }
}

const getCountDetails = () => async (dispatch) => {
  try {
    dispatch(getInvoiceCountAction.do())
    const resp = await get('sales', 'invoices/get-count-detail', 'GET')
    dispatch(getInvoiceCountAction.success(resp.data))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting Count.'
    return dispatch(showErrorToastAction(error))
  }
}

const getSalesChart = () => async (dispatch) => {
  try {
    dispatch(getSalesChartAction.do())
    const resp = await get('sales', 'get-sales-chart', 'GET')
    dispatch(getSalesChartAction.success(resp.data))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting Count.'
    return dispatch(showErrorToastAction(error))
  }
}

const initSelectedInvoice = () => (dispatch) => {
  dispatch(createAction(INIT_SELECTED_INVOICE))
}
const setSelectedInvoiceLines = (lines) => (dispatch) => {
  dispatch(createAction(SET_INVOICE_LINES, { payload: lines }))
}
const setInvoiceInstallments = (installments) => (dispatch) => {
  dispatch(createAction(SET_INVOICE_INSTALLMENTS, { payload: installments }))
}
const receivePayment = (params) => async (dispatch) => {
  try {
    dispatch(createReceivePaymentAction.do())
    const resp = await post('sales', `receive-payment`, params)
    dispatch(createReceivePaymentAction.success(resp))
    getCountDetails()
    dispatch(showSuccessToastAction('Successfully created.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating invoice.'
    dispatch(createReceivePaymentAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const getReceivePaymentCustomerData = (custId) => async () => {
  try {
    if (custId) {
      const resp = await get('sales', `get-receive-payment-customer-data/${custId}`, 'GET')
      return resp.data
    }
  } catch (e) {
    return e
  }
}

const getTransactionInfoByCustomer =
  (custId, transactionType = null) =>
  async (dispatch) => {
    try {
      if (custId) {
        let url = `contact/${custId}/transaction-info-list`
        if (transactionType && Array.isArray(transactionType)) {
          url += `?type=${transactionType}`
        }
        const resp = await get('sales', url, 'GET')
        return resp
      }
    } catch (e) {
      const error = e.body ? e.body : `An error occurred while fetching invoices`
      dispatch(showErrorToastAction(error))
    }
  }

const sendEmailInv = (id, params) => async (dispatch) => {
  try {
    await post('sales', `send-email/${id}`, params)
    dispatch(showSuccessToastAction('Successfully created.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating invoice.'
    dispatch(showErrorToastAction(error))
  }
}

const sendWeChatInv = (id, params) => async (dispatch) => {
  try {
    await post('sales', `send-wechat/${id}`, params)
    dispatch(showSuccessToastAction('Successfully created.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating invoice.'
    dispatch(showErrorToastAction(error))
  }
}

const sendWhatsAppInv = (id, params) => async (dispatch) => {
  try {
    await post('sales', `send-whatsapp/${id}`, params)
    dispatch(showSuccessToastAction('Successfully created.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating invoice.'
    dispatch(showErrorToastAction(error))
  }
}

const removeAttachment = (id) => async (dispatch) => {
  try {
    await destroy('sales', `invoices/${id}/remove-attachment`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    return true
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while deleting invoice attachment.'
    dispatch(showErrorToastAction(error))
  }
}

const allSales =
  (pageStart = 1, pageLength = 10, filter = {}) =>
  async (dispatch) => {
    try {
      const filterStr = JSON.stringify(filter)
      const resp = await get(
        'sales',
        `all-sales?page=${pageStart}&page_length=${pageLength}&search=${filterStr}`,
        'GET',
      )
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting invoices.'
      dispatch(showErrorToastAction(error))
      return false
    }
  }

const deletePayment = (id) => async (dispatch) => {
  try {
    await destroy('sales', `receive-payment/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    return true
  } catch (e) {
    const error = e.body ? e.body : 'An unknown error occurred.'
    dispatch(showErrorToastAction(error))
    return false
  }
}

const getPaymentById = (id) => async (dispatch) => {
  try {
    const resp = await get('sales', `receive-payment/${id}`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting invoice data.'
    return dispatch(showErrorToastAction(error))
  }
}

const editReceivePayment = (params, id) => async (dispatch) => {
  try {
    dispatch(createReceivePaymentAction.do())
    const resp = await put('sales', `receive-payment/${id}`, params)
    dispatch(createReceivePaymentAction.success(resp))
    getCountDetails()
    dispatch(showSuccessToastAction('Successfully Updated.'))
    navigateToPage('/sales/invoices')
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating invoice.'
    dispatch(createReceivePaymentAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const getInvoiceDataArray =
  (pageStart = 1, pageLength = 10, filter = {}) =>
  async (dispatch) => {
    try {
      const filterStr = JSON.stringify(filter)
      const resp = await get(
        'sales',
        `invoices?page=${pageStart}&page_length=${pageLength}&search=${filterStr}`,
        'GET',
      )
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting invoices.'
      return dispatch(showErrorToastAction(error))
    }
  }

const getSalesNextNumbers = (salesType) => async (dispatch) => {
  try {
    const resp = await get('sales', `${salesType}/get-${salesType}-number`, 'GET')
    return resp?.data
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting latest number.'
    return dispatch(showErrorToastAction(error))
  }
}

const getNextAvailableNumber = (params) => async (dispatch) => {
  try {
    const resp = await post('sales', `calculate-next-transaction-number`, params)
    return resp?.data
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting latest number.'
    return dispatch(showErrorToastAction(error))
  }
}

const getRecurringDates = async (details) => {
  try {
    const resp = await post('sales', 'recurring/recurring-dates', details)
    return resp
  } catch (e) {
    return []
  }
}

const downloadAllSalesPdfReport = () => async (dispatch) => {
  try {
    const resp = await printPdf('sales', `all-sales-pdf`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting sales.'
    return dispatch(showErrorToastAction(error))
  }
}

const downloadAllSalesExcelReport = () => async (dispatch) => {
  try {
    const resp = await download('sales', `all-sales-excel`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting products.'
    return dispatch(showErrorToastAction(error))
  }
}

const downloadCustomerTransactionPdfReport = (id) => async (dispatch) => {
  try {
    const resp = await printPdf('sales', `contacts/customer-transactions/${id}/pdf`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting sales.'
    return dispatch(showErrorToastAction(error))
  }
}

const downloadCustomerTransactionExcelReport = (id) => async (dispatch) => {
  try {
    const resp = await download('sales', `contacts/customer-transactions/${id}/excel`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting products.'
    return dispatch(showErrorToastAction(error))
  }
}

export {
  createInvoiceAction,
  updateInvoiceAction,
  getInvoicesAction,
  getCustomerLedgerAction,
  getInvoiceByIdAction,
  deleteInvoiceAction,
  createReceivePaymentAction,
  getSalesChartAction,
  getInvoices,
  getCustomerLedger,
  getInvoiceById,
  createInvoice,
  updateInvoice,
  deleteInvoice,
  getInvoiceTerms,
  setInvoiceTerms,
  initSelectedInvoice,
  getLatestInvoiceNumber,
  setSelectedInvoiceLines,
  getInvoicePDFUrl,
  getprintDoc,
  setInvoiceInstallments,
  receivePayment,
  getReceivePaymentCustomerData,
  voidInvoice,
  getCountDetails,
  getSalesChart,
  sendEmailInv,
  sendWeChatInv,
  sendWhatsAppInv,
  removeAttachment,
  allSales,
  deletePayment,
  getPaymentById,
  editReceivePayment,
  getInvoiceDataArray,
  getSalesNextNumbers,
  getRecurringDates,
  getTransactionInfoByCustomer,
  downloadAllSalesPdfReport,
  downloadAllSalesExcelReport,
  downloadCustomerTransactionPdfReport,
  downloadCustomerTransactionExcelReport,
  getNextAvailableNumber,
}
