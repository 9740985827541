import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { destroy, get, post, put, download, printPdf } from '../../../utils/request'

export const GET_EXPENSES = createTypes('ZOOM/TRANSACTION/GET_EXPENSES')
export const CREATE_EXPENSE = createTypes('ZOOM/TRANSACTION/CREATE_EXPENSE')
export const COPY_EXPENSE = createTypes('ZOOM/TRANSACTION/COPY_EXPENSE')
export const GET_EXPENSE_BY_ID = createTypes('ZOOM/TRANSACTION/GET_EXPENSE_BY_ID')
export const DELETE_EXPENSE = createTypes('ZOOM/TRANSACTION/DELETE_EXPENSE')
export const VOID_EXPENSE = createTypes('ZOOM/TRANSACTION/VOID_EXPENSE')
export const UPDATE_EXPENSE = createTypes('ZOOM/TRANSACTION/UPDATE_EXPENSE')
export const INIT_SELECTED_EXPENSE = 'ZOOM/TRANSACTION/INIT_SELECTED_EXPENSE'
export const GET_EXPENSES_BY_SUPPLIERID = createTypes('ZOOM/TRANSACTION/GET_EXPENSES_BY_SUPPLIERID')
export const SET_CURRENT_PAGE = 'ZOOM/SALES/SET_INVOICE_CURRENT_PAGE'
export const SET_SIZE_PER_PAGE = 'ZOOM/SALES/SET_INVOICE_SIZE_PER_PAGE'
export const SET_TOTAL_SIZE = 'ZOOM/SALES/SET_INVOICE_TOTAL_SIZE'
export const setSizePerPage = (params) => createAction(SET_SIZE_PER_PAGE, { payload: params })
export const setCurrentPage = (params) => createAction(SET_CURRENT_PAGE, { payload: params })
export const setTotalSize = (params) => createAction(SET_TOTAL_SIZE, { payload: params })

const getExpensesAction = {
  do: (params) => createAction(GET_EXPENSES.DO, params),
  success: (expenses) => createAction(GET_EXPENSES.SUCCESS, { payload: expenses }),
  failed: (errors) => createAction(GET_EXPENSES.FAILED, { payload: errors }),
}

const createExpenseAction = {
  do: (params) => createAction(CREATE_EXPENSE.DO, params),
  success: () => createAction(CREATE_EXPENSE.SUCCESS),
  failed: (errors) => createAction(CREATE_EXPENSE.FAILED, { errors }),
}

const copyExpenseAction = {
  do: (params) => createAction(COPY_EXPENSE.DO, params),
  success: () => createAction(COPY_EXPENSE.SUCCESS),
  failed: (errors) => createAction(COPY_EXPENSE.FAILED, { errors }),
}

const getExpenseByIdAction = {
  do: (params) => createAction(GET_EXPENSE_BY_ID.DO, params),
  success: (data) => createAction(GET_EXPENSE_BY_ID.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_EXPENSE_BY_ID.FAILED, { errors }),
}

const deleteExpenseAction = {
  do: () => createAction(DELETE_EXPENSE.DO),
  success: () => createAction(DELETE_EXPENSE.SUCCESS),
  failed: (errors) => createAction(DELETE_EXPENSE.FAILED, { errors }),
}

const voidExpenseAction = {
  do: () => createAction(VOID_EXPENSE.DO),
  success: () => createAction(VOID_EXPENSE.SUCCESS),
  failed: (errors) => createAction(VOID_EXPENSE.FAILED, { errors }),
}

const updateExpenseAction = {
  do: (params) => createAction(UPDATE_EXPENSE.DO, params),
  success: () => createAction(UPDATE_EXPENSE.SUCCESS),
  failed: (errors) => createAction(UPDATE_EXPENSE.FAILED, { errors }),
}

const getExpensesBySupplierIDAction = {
  do: (params) => createAction(GET_EXPENSES_BY_SUPPLIERID.DO, params),
  success: (expenses) => createAction(GET_EXPENSES_BY_SUPPLIERID.SUCCESS, { payload: expenses }),
  failed: (errors) => createAction(GET_EXPENSES_BY_SUPPLIERID.FAILED, { payload: errors }),
}

const getExpenses =
  (pageStart = 0, pageLength = 10) =>
  async (dispatch) => {
    try {
      dispatch(getExpensesAction.do())
      dispatch(setCurrentPage(pageStart))
      dispatch(setSizePerPage(pageLength))
      const resp = await get(
        'expense',
        `expenses?page=${pageStart}&page_length=${pageLength}&transaction_type=expenses`,
        'GET',
      )
      dispatch(setTotalSize(resp.total))
      dispatch(getExpensesAction.success(resp.data))
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting expenses.'
      dispatch(getExpensesAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const createExpense = (params) => async (dispatch) => {
  try {
    dispatch(createExpenseAction.do())
    const resp = await post('expense', `expenses`, params)
    dispatch(createExpenseAction.success(resp))
    dispatch(showSuccessToastAction('Successfully created.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating expense data.'
    dispatch(createExpenseAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const copyExpense = (params) => async (dispatch) => {
  try {
    dispatch(copyExpenseAction.do())
    const resp = await post('expense', `copy-expense`, params)
    dispatch(copyExpenseAction.success(resp))
    dispatch(showSuccessToastAction('Copy Successfully.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while copy expense data.'
    dispatch(copyExpenseAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const getExpenseById = (id) => async (dispatch) => {
  try {
    dispatch(getExpenseByIdAction.do())
    if (id) {
      const resp = await get('expense', `expenses/${id}`, 'GET')
      var expenseData = resp
      let addresses = {}
      resp.addresses.forEach((address) => {
        addresses[address.type] = address
      })
      expenseData.categories = resp.transaction.tran_list
      expenseData.items = resp.transaction.tran_item
      expenseData.tags = resp.transaction.tran_tag
      expenseData.attachment = resp?.attachment ? resp?.attachment : []
      dispatch(getExpenseByIdAction.success({ ...expenseData, addresses: addresses }))
    } else {
      await dispatch(createAction(INIT_SELECTED_EXPENSE))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting expense data.'
    dispatch(getExpenseByIdAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const deleteExpense = (id) => async (dispatch) => {
  try {
    dispatch(deleteExpenseAction.do())
    const resp = await destroy('expense', `expenses/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    dispatch(deleteExpenseAction.success(resp))
    return true
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while deleting expense data.'
    dispatch(deleteExpenseAction.failed(error))
    dispatch(showErrorToastAction(error))
    return false
  }
}

const voidExpense = (id) => async (dispatch) => {
  try {
    dispatch(voidExpenseAction.do())
    const resp = await get('expense', `void-expense/${id}`, 'GET')
    dispatch(showSuccessToastAction('Successfully voided.'))
    dispatch(voidExpenseAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while voiding expense data.'
    dispatch(voidExpenseAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const updateExpense = (id, params) => async (dispatch) => {
  try {
    dispatch(updateExpenseAction.do())
    const resp = await put('expense', `expenses/${id}`, params)
    dispatch(showSuccessToastAction('Successfully updated.'))
    dispatch(updateExpenseAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating expense data.'
    dispatch(updateExpenseAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const getExpensesBySupplierID =
  (id, pageStart = 0, pageLength = 1000) =>
  async (dispatch) => {
    try {
      dispatch(getExpensesBySupplierIDAction.do())
      const resp = await get(
        'expense',
        `transaction-list-by-supplier?page=${pageStart}&page_length=${pageLength}&contactID=${id}&transaction_type=expenses`,
        'GET',
      )
      dispatch(getExpensesBySupplierIDAction.success(resp[0].data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting expenses.'
      dispatch(getExpensesBySupplierIDAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const removeAttachment = (id) => async (dispatch) => {
  try {
    await destroy('expense', `expense/${id}/remove-attachment`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    return true
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while deleting invoice attachment.'
    dispatch(showErrorToastAction(error))
  }
}
const getLatestExpenseNumber = async () => {
  try {
    const resp = await get('expense', 'expense/generate-expense-number', 'GET')
    return resp
  } catch (e) {
    // eslint-disable-next-line no-unused-vars
    const error = e.body ? e.body : 'An error occurred while getting latest invoice number.'
  }
}

const getExpensePDFUrl = async (id) => async (dispatch) => {
  try {
    const resp = await printPdf('expense', `expense/${id}/get-expense-pdf`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting expense pdf.'
    return dispatch(showErrorToastAction(error))
  }
}

const downloadReport = () => async (dispatch) => {
  try {
    const resp = await printPdf('expense', `all-expenses-pdf`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting sales.'
    return dispatch(showErrorToastAction(error))
  }
}

const downloadExcelReport = () => async (dispatch) => {
  try {
    const resp = await download('expense', `all-expenses-excel`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting products.'
    return dispatch(showErrorToastAction(error))
  }
}

const getNextAvailableNumber = (params) => async (dispatch) => {
  try {
    const resp = await post('expense', `calculate-next-transaction-number`, params)
    return resp?.data
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting latest number.'
    return dispatch(showErrorToastAction(error))
  }
}

export {
  getExpensesAction,
  createExpenseAction,
  copyExpenseAction,
  getExpenseByIdAction,
  deleteExpenseAction,
  voidExpenseAction,
  updateExpenseAction,
  getExpensesBySupplierIDAction,
  getExpenses,
  createExpense,
  copyExpense,
  getExpenseById,
  deleteExpense,
  voidExpense,
  updateExpense,
  getExpensesBySupplierID,
  removeAttachment,
  getLatestExpenseNumber,
  getExpensePDFUrl,
  downloadReport,
  downloadExcelReport,
  getNextAvailableNumber,
}
