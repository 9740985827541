import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { post, get, put, destroy } from '../../../utils/request'

export const CREATE_DEPOSIT = createTypes('ZOOM/BANKING/CREATE_DEPOSIT')

const createDepositAction = {
  do: (params) => createAction(CREATE_DEPOSIT.DO, params),
  success: () => createAction(CREATE_DEPOSIT.SUCCESS),
  failed: (errors) => createAction(CREATE_DEPOSIT.FAILED, { errors }),
}
const createDeposit = (params) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(createDepositAction.do())
      await post('banking', 'deposits', params)
      dispatch(createDepositAction.success())
      dispatch(showSuccessToastAction('Successfully created.'))
      resolve()
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while creating bank deposit.'
      dispatch(createDepositAction.failed(error))
      dispatch(showErrorToastAction(error))
      reject()
    }
  })
}
const getDepositById = (id) => async (dispatch) => {
  try {
    const resp = await get('banking', `deposits/${id}`, 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating bank deposit.'
    dispatch(showErrorToastAction(error))
  }
}
const updateDeposite = (params, id) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(createDepositAction.do())
      await put('banking', `deposits/${id}`, params)
      dispatch(createDepositAction.success())
      dispatch(showSuccessToastAction('Successfully created.'))
      resolve()
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while updating bank deposit.'
      dispatch(createDepositAction.failed(error))
      dispatch(showErrorToastAction(error))
      reject()
    }
  })
}

const deleteDeposit = (id) => async (dispatch, getState) => {
  try {
    // eslint-disable-next-line no-unused-vars
    const resp = await destroy('banking', `deposits/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    return true
  } catch (e) {
    const error = e.body ? e.body : 'An unknown error occurred.'
    dispatch(showErrorToastAction(error))
    return false
  }
}

const getLatestDepositNumber = () => async (dispatch) => {
  try {
    const resp = await get('banking', 'deposits/latest-deposit-number', 'GET')
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting latest deposit number.'
    return dispatch(showErrorToastAction(error))
  }
}
export {
  createDepositAction,
  createDeposit,
  getDepositById,
  updateDeposite,
  deleteDeposit,
  getLatestDepositNumber,
}
