import { createAction, createTypes } from '../../../utils/actions'
import { get, post } from '../../../utils/request'
const { showErrorToastAction, showSuccessToastAction } = require('../global')

export const SET_CUSTOMIZATION_SETTING_ACTION = createTypes(
  'ZOOM/SETTINGS/SET_CUSTOMIZATION_SETTING',
)

const setCustomizationSettingAction = {
  do: () => createAction(SET_CUSTOMIZATION_SETTING_ACTION.DO),
  success: (payload) => createAction(SET_CUSTOMIZATION_SETTING_ACTION.SUCCESS, { payload }),
  failed: (errors) => createAction(SET_CUSTOMIZATION_SETTING_ACTION.FAILED, { errors }),
}

const setTransactionSeriesNumbers = (data) => async (dispatch) => {
  try {
    const resp = await post(`user`, 'api/settings/customizations/tnx-number-series', data)
    dispatch(showSuccessToastAction('Transaction Series Numbers has been updated'))
    return resp?.data
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating transaction series numbers'
    return dispatch(showErrorToastAction(error))
  }
}

const getCustomizationSettings = () => async (dispatch) => {
  try {
    dispatch(setCustomizationSettingAction.do())
    const resp = await get('user', 'api/settings/customizations', 'GET')
    dispatch(setCustomizationSettingAction.success(resp.data))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating organization setting'
    dispatch(setCustomizationSettingAction.failed(error))
  }
}

const setPdfTempalte = (data) => async (dispatch) => {
  try {
    const resp = await post(`user`, 'api/settings/customizations/pdf', data)
    dispatch(showSuccessToastAction('PDF Template has been updated'))
    return resp?.data
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating PDF Template'
    return dispatch(showErrorToastAction(error))
  }
}

const getPdfSettings = () => async (dispatch) => {
  try {
    const resp = await get('user', 'api/settings/customizations/pdfSettings', 'GET')
    return resp?.data
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while Geting PDF Template'
    return dispatch(showErrorToastAction(error))
  }
}
export { setTransactionSeriesNumbers, getCustomizationSettings, setPdfTempalte, getPdfSettings }
