import React, { useEffect, useState } from 'react'
import { CFormInput, CFormSelect, CFormTextarea, CMultiSelect } from '@coreui/react-pro'
import FormInputWrapper from '../components/FormInputWrapper'
import GoogleAddressInput from '../components/common/GoogleAddressInput'
import GoogleAddressInputCustom from '../components/common/GoogleAddressInputCustom'
import ZFormLabel from './ZFormLabel'
import { ZButton } from './index'
import { default as ReactSelect } from 'react-select'

const Text = (inputProps) => {
  let props = { ...inputProps }
  return (
    <FormInputWrapper className="w-100">
      <div className="mb-3">
        {props.label && (
          <ZFormLabel className="common-form-label fw-light" htmlFor={props.name}>
            {props.label}
          </ZFormLabel>
        )}
        <CFormInput
          className="common-form-input"
          style={{ padding: '3px', textAlign: 'left' }}
          {...props}
        >
          {props.children}
        </CFormInput>
      </div>
    </FormInputWrapper>
  )
}

const Number = (inputProps) => {
  let props = { ...inputProps }
  return (
    <FormInputWrapper className="w-100">
      <div className="mb-3">
        {props.label && (
          <ZFormLabel className="common-form-label fw-light" htmlFor={props.name}>
            {props.label}
          </ZFormLabel>
        )}

        <CFormInput
          type="number"
          className="common-form-input"
          style={{ padding: '3px', textAlign: 'left' }}
          {...props}
        >
          {props.children}
        </CFormInput>
      </div>
    </FormInputWrapper>
  )
}

const Textarea = (textAreaProps) => {
  let props = { ...textAreaProps }
  if (props.hasOwnProperty('value')) {
    if (!props.value) props.value = ''
  }
  return (
    <FormInputWrapper className="w-100">
      <div className="mb-3">
        {props.label && (
          <ZFormLabel className="common-form-label fw-light" htmlFor={props.name}>
            {props.label}
          </ZFormLabel>
        )}
        <CFormTextarea className="common-text-area" {...props} aria-label="With textarea" />
      </div>
    </FormInputWrapper>
  )
}
const Select = (selectProps) => {
  let props = { ...selectProps }
  if (props.hasOwnProperty('value')) {
    if (!props.value) props.value = ''
  }
  return (
    <FormInputWrapper>
      <div className="mb-3">
        {props.label && (
          <ZFormLabel htmlFor={props.name} className="common-form-label fw-light">
            {props.label}
          </ZFormLabel>
        )}
        {props.multiple ? (
          <CMultiSelect
            className="common-form-input"
            options={props.items}
            onChange={(e) => props.onChange(e)}
          />
        ) : (
          <CFormSelect
            className=" common-form-label fw-light mb-2"
            aria-label="Large select example"
            {...props}
          >
            {!props.defaultText ? (
              <option value={null}>--Select--</option>
            ) : (
              <option value="">{props.defaultText}</option>
            )}
            {props.items && props.items.length > 1 && props.items[0].hasOwnProperty('optgroup')
              ? props.items.map((itemGroup) => {
                  return itemGroup.options.map((item, key) => {
                    return (
                      <>
                        ( {!key && <optgroup label={itemGroup.optgroup}></optgroup>})
                        <option key={key} value={item.value}>
                          {item.label}
                        </option>
                      </>
                    )
                  })
                })
              : props.items &&
                props.items.map((item, key) => (
                  <option key={key} value={item.value}>
                    {item.label}
                  </option>
                ))}
          </CFormSelect>
        )}
      </div>
    </FormInputWrapper>
  )
}

const CustomSelect = (selectProps) => {
  let props = { ...selectProps }
  if (props.hasOwnProperty('value')) {
    if (!props.value) props.value = ''
  }

  const [options, setOptions] = useState(props.items)
  useEffect(() => {
    setOptions(props.items)
  }, [props.items])
  const handleProductSearch = async (e) => {
    let search = e.target.value
    let options = props.items?.filter((item) =>
      item.label.toLowerCase().includes(search.toLowerCase()),
    )
    if (search.length === 0) {
      setOptions(props.items)
    } else {
      setOptions(options)
    }
  }
  const onChangeSelect = (e) => {
    if (!e) {
      e = {
        value: '',
      }
    }
    props.onChange(e)
  }
  return (
    <FormInputWrapper>
      <div className="mb-3">
        {props.label && (
          <ZFormLabel htmlFor={props.name} className="common-form-label fw-light">
            {props.label}
          </ZFormLabel>
        )}
        {props.multiple ? (
          <CMultiSelect
            className="common-form-label fw-light"
            options={props.items}
            onChange={(e) => props.onChange(e)}
          />
        ) : (
          <>
            <ReactSelect
              issearchable="true"
              isclearable="true"
              isClearable
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  borderColor: '#CFECEC',
                  primary25: '#CFECEC',
                  focusBorderColor: '#CFECEC',
                },
              })}
              className="basic-multi-select"
              onKeyDown={(e) => handleProductSearch(e)}
              onChange={onChangeSelect}
              classnameprefix="select"
              options={options}
              value={options?.filter((option) => option.value === props.value)}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: -1 }) }}
              menuContainerStyle={{ zIndex: 9999999999 }}
              isDisabled={props?.disabled ?? false}
              components={props.components ?? {}}
            />
          </>
        )}
      </div>
    </FormInputWrapper>
  )
}
const Address = ({
  label = '',
  isGoogleAddress = true,
  onChange,
  defaultValue,
  disableManual = false,
  disabled,
}) => {
  const [isManually, setManually] = useState(false)
  useEffect(() => {
    setManually(!isGoogleAddress)
  }, [isGoogleAddress])
  const onManualAddressChange = (addressText) => {
    const address = {
      administrative_area_level_1: '',
      administrative_area_level_2: '',
      country: '',
      formatted_address: addressText,
      google_place_id: '',
      locality: '',
      postal_code: '',
      route: '',
      street_number: '',
      latitude: '',
      longitude: '',
      is_google_address: false,
    }
    onChange(address)
  }
  return (
    <FormInputWrapper>
      <div className="mb-3">
        <ZFormLabel className="common-form-label fw-light">{label}</ZFormLabel>
        {isManually ? (
          <Text
            className="form-control common-form-input"
            defaultValue={defaultValue}
            onChange={(e) => {
              onManualAddressChange(e.target.value)
            }}
          />
        ) : (
          <GoogleAddressInput
            onAddressChanged={(address) => onChange({ ...address, is_google_address: true })}
            className="form-control common-form-input"
            defaultValue={defaultValue}
          />
        )}
        {disabled
          ? null
          : !disableManual && (
              <ZButton
                type="button"
                color="transparent"
                size="sm"
                className="text text-primary"
                onClick={() => setManually(!isManually)}
              >
                {isManually ? 'Use auto complete' : 'Enter manually'}
              </ZButton>
            )}
      </div>
    </FormInputWrapper>
  )
}

const AddressCustomer = ({
  label = '',
  isGoogleAddress = true,
  onChange,
  defaultValue,
  billing = {},
  disableManual = false,
  disabled,
}) => {
  const [isManually, setManually] = useState(false)
  useEffect(() => {
    setManually(!isGoogleAddress)
  }, [isGoogleAddress])
  const onManualAddressChange = (addressText) => {
    const address = {
      ...billing,
      formatted_address: addressText,
    }
    onChange(address)
  }

  return (
    <FormInputWrapper>
      <div className="mb-1">
        <ZFormLabel className="common-form-label fw-light">{label}</ZFormLabel>
        {isManually ? (
          <Text
            className="form-control common-form-input"
            value={defaultValue}
            onChange={(e) => {
              onManualAddressChange(e.target.value)
            }}
            disabled={disabled}
          />
        ) : (
          <GoogleAddressInputCustom
            onAddressChanged={(address) => onChange({ ...address, is_google_address: true })}
            className="form-control common-form-input"
            defaultValue={defaultValue}
            disabled={disabled}
          />
        )}
      </div>
      {disabled
        ? null
        : !disableManual && (
            <ZButton
              type="button"
              color="transparent"
              size="sm"
              className="text text-primary"
              onClick={() => setManually(!isManually)}
            >
              {isManually ? 'Use auto complete' : 'Enter manually'}
            </ZButton>
          )}
    </FormInputWrapper>
  )
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Address,
  Text,
  Number,
  Select,
  Textarea,
  AddressCustomer,
  CustomSelect,
}
