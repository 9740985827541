import React from 'react'

class GoogleAddressInputCustom extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addressValue: '',
    }
    this.autocompleteInput = React.createRef()
    this.autocomplete = null
    this.sessionToken = new window.google.maps.places.AutocompleteSessionToken() // Session token for billing optimization
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
  }

  componentDidMount() {
    this.autocomplete = new window.google.maps.places.Autocomplete(this.autocompleteInput.current, {
      types: [], // Prioritize address suggestions
      fields: ['address_components', 'formatted_address', 'geometry', 'place_id', 'name'], // Fetch only necessary fields
    })

    this.autocomplete.setOptions({
      sessionToken: this.sessionToken, // Attach session token
    })

    this.autocomplete.addListener('place_changed', this.handlePlaceChanged)
  }

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace()
    const address = this.extractAddress(place)

    // Reset session token after each selection
    this.sessionToken = new window.google.maps.places.AutocompleteSessionToken()

    // Trigger the callback with the address
    this.props.onAddressChanged(address)
  }

  extractAddress(place) {
    const { name, address_components, place_id, geometry } = place

    const getAddrPropByKey = (key) =>
      address_components?.find((component) => component.types.includes(key))?.short_name || ''

    const address = {
      street_number: getAddrPropByKey('street_number'),
      route: getAddrPropByKey('route'),
      locality: getAddrPropByKey('locality'),
      administrative_area_level_1: getAddrPropByKey('administrative_area_level_1'),
      administrative_area_level_2: getAddrPropByKey('administrative_area_level_2'),
      country: getAddrPropByKey('country'),
      postal_code: getAddrPropByKey('postal_code'),
      formatted_address: name || '',
      google_place_id: place_id || '',
      latitude: geometry?.location.lat() || '',
      longitude: geometry?.location.lng() || '',
    }

    this.setState({ addressValue: name })
    return address
  }

  render() {
    const { className, name, id, placeHolder, defaultValue, disabled } = this.props
    return (
      <input
        ref={this.autocompleteInput}
        className={className}
        name={name}
        id={id}
        placeholder={placeHolder}
        type="text"
        value={this.state.addressValue || defaultValue}
        onChange={(e) => this.setState({ addressValue: e.target.value })}
        autoComplete="off"
        disabled={disabled}
      />
    )
  }
}

export default GoogleAddressInputCustom
