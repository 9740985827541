import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  CREATE_EXPENSE,
  COPY_EXPENSE,
  DELETE_EXPENSE,
  VOID_EXPENSE,
  GET_EXPENSE_BY_ID,
  GET_EXPENSES,
  UPDATE_EXPENSE,
  INIT_SELECTED_EXPENSE,
  SET_TOTAL_SIZE,
  SET_CURRENT_PAGE,
  SET_SIZE_PER_PAGE,
} from '../../actions/expense/transactionExpense'

const EMPTY_EXPENSE = {
  id: '',
  contact_id: '',
  payment_account: '',
  payment_date: '',
  ref_no: '',
  payment_method: '',
  tags: '',
  total: '',
  memo: '',
  categories: [],
  items: [],
  is_recurring: false,
  addresses: {
    billing: null,
    location: null,
    shipping: null,
  },
}

const DEFAULT_EXPENSE = {
  id: '',
  contact_id: '',
  payment_account: '',
  payment_date: '',
  ref_no: '',
  payment_method: '',
  tags: '',
  total: '',
  memo: '',
  categories: [],
  items: [],
  is_recurring: false,
  addresses: {
    billing: null,
    location: null,
    shipping: null,
  },
}

const CREATE_REQUEST_EXPENSE = {
  contact_id: '',
  payment_account: '',
  payment_date: '',
  ref_no: '',
  payee: '',
  payment_method: '',
  total: '',
  memo: '',
  transaction_list: [],
  transaction_tag: [],
  attachment: [],
  addresses: {
    billing: null,
    location: null,
    shipping: null,
  },
}

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
  isVoiding: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  expenses: [],
  emptyExpense: EMPTY_EXPENSE,
  selectedExpense: DEFAULT_EXPENSE,
  createRequestExpense: CREATE_REQUEST_EXPENSE,
  paginationParams: {
    page: 1,
    sizePerPage: 10,
    totalSize: 0,
  },
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setExpenses = (data) => (state) => state.setIn(['expenses'], data)

const setEmptyExpense = (data) => (state) => state.setIn(['emptyExpense'], data)

const setSelectedExpense = (data) => (state) => state.setIn(['selectedExpense'], data)

const setCreateRequestExpense = (data) => (state) => state.setIn(['createRequestExpense'], data)

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)

const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)

const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)

const resetStatusSetVoiding = () => (state) =>
  resetStatus()(state).setIn(['status', 'isVoiding'], true)

const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
const setTotalSize = (total) => (state) => state.setIn(['paginationParams', 'totalSize'], total)
const setCurrentPage = (total) => (state) => state.setIn(['paginationParams', 'page'], total)
const setSizePerPage = (total) => (state) => state.setIn(['paginationParams', 'sizePerPage'], total)

function transactionExpenseReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_EXPENSES.DO:
      return mutate([resetStatusSetLoading()])
    case GET_EXPENSES.SUCCESS:
      return mutate([resetStatus(), setExpenses(action.payload)])
    case GET_EXPENSES.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setExpenses([])])
    case CREATE_EXPENSE.DO:
      return mutate([
        resetStatusSetSaving(),
        setSelectedExpense(DEFAULT_EXPENSE),
        setEmptyExpense(EMPTY_EXPENSE),
      ])
    case CREATE_EXPENSE.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_EXPENSE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case COPY_EXPENSE.DO:
      return mutate([resetStatusSetSaving()])
    case COPY_EXPENSE.SUCCESS:
      return mutate([resetStatus()])
    case COPY_EXPENSE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_EXPENSE.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_EXPENSE.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_EXPENSE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_EXPENSE.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_EXPENSE.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_EXPENSE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case VOID_EXPENSE.DO:
      return mutate([resetStatusSetVoiding()])
    case VOID_EXPENSE.SUCCESS:
      return mutate([resetStatus()])
    case VOID_EXPENSE.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case GET_EXPENSE_BY_ID.DO:
      return mutate([resetStatusSetLoading()])
    case GET_EXPENSE_BY_ID.SUCCESS:
      return mutate([resetStatus(), setSelectedExpense(action.payload)])
    case GET_EXPENSE_BY_ID.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setSelectedExpense(DEFAULT_EXPENSE)])
    case INIT_SELECTED_EXPENSE:
      return mutate([
        resetStatus(),
        setCreateRequestExpense(CREATE_REQUEST_EXPENSE),
        setSelectedExpense(DEFAULT_EXPENSE),
        setEmptyExpense(EMPTY_EXPENSE),
      ])
    case SET_TOTAL_SIZE:
      return mutate([setTotalSize(action.payload)])
    case SET_CURRENT_PAGE:
      return mutate([setCurrentPage(action.payload)])
    case SET_SIZE_PER_PAGE:
      return mutate([setSizePerPage(action.payload)])
    default:
      return state
  }
}

export default transactionExpenseReducer
