import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  CREATE_CHECK,
  COPY_CHECK,
  DELETE_CHECK,
  VOID_CHECK,
  GET_CHECK_BY_ID,
  GET_CHECKS,
  UPDATE_CHECK,
  INIT_SELECTED_CHECK,
} from '../../actions/expense/transactionCheck'

const EMPTY_CHECK = {
  id: '',
  contact_id: '',
  payment_account: '',
  check_no: '',
  payment_date: '',
  mailing_address: '',
  print_late: 0,
  tags: '',
  total: '',
  memo: '',
  categories: [],
  items: [],
}

const DEFAULT_CHECK = {
  id: '',
  contact_id: '',
  payment_account: '',
  check_no: '',
  payment_date: '',
  mailing_address: '',
  print_late: 0,
  tags: '',
  total: '',
  memo: '',
  categories: [],
  items: [],
}

const CREATE_REQUEST_CHECK = {
  contact_id: '',
  payment_account: '',
  check_no: '',
  payment_date: '',
  mailing_address: '',
  print_late: 0,
  tags: '',
  total: '',
  memo: '',
  transaction_list: [],
  transaction_tag: [],
  attachment: [],
}

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
  isVoiding: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  checks: [],
  emptyCheck: EMPTY_CHECK,
  selectedCheck: DEFAULT_CHECK,
  createRequestCheck: CREATE_REQUEST_CHECK,
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setChecks = (data) => (state) => state.setIn(['checks'], data)

const setEmptyCheck = (data) => (state) => state.setIn(['emptyCheck'], data)

const setSelectedCheck = (data) => (state) => state.setIn(['selectedCheck'], data)

const setCreateRequestCheck = (data) => (state) => state.setIn(['createRequestCheck'], data)

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)

const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)

const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)

const resetStatusSetVoid = () => (state) =>
  resetStatus()(state).setIn(['status', 'isVoiding'], true)

const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)

function transactionCheckReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_CHECKS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_CHECKS.SUCCESS:
      return mutate([resetStatus(), setChecks(action.payload)])
    case GET_CHECKS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setChecks([])])
    case CREATE_CHECK.DO:
      return mutate([
        resetStatusSetSaving(),
        setSelectedCheck(DEFAULT_CHECK),
        setEmptyCheck(EMPTY_CHECK),
      ])
    case CREATE_CHECK.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_CHECK.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case COPY_CHECK.DO:
      return mutate([resetStatusSetSaving()])
    case COPY_CHECK.SUCCESS:
      return mutate([resetStatus()])
    case COPY_CHECK.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_CHECK.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_CHECK.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_CHECK.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_CHECK.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_CHECK.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_CHECK.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case VOID_CHECK.DO:
      return mutate([resetStatusSetVoid()])
    case VOID_CHECK.SUCCESS:
      return mutate([resetStatus()])
    case VOID_CHECK.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case GET_CHECK_BY_ID.DO:
      return mutate([resetStatusSetLoading()])
    case GET_CHECK_BY_ID.SUCCESS:
      return mutate([resetStatus(), setSelectedCheck(action.payload)])
    case GET_CHECK_BY_ID.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setSelectedCheck(DEFAULT_CHECK)])
    case INIT_SELECTED_CHECK:
      return mutate([
        resetStatus(),
        setCreateRequestCheck(CREATE_REQUEST_CHECK),
        setSelectedCheck(DEFAULT_CHECK),
        setEmptyCheck(EMPTY_CHECK),
      ])
    default:
      return state
  }
}

export default transactionCheckReducer
