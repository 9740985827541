import { createAction, createTypes } from '../../../utils/actions'
import { get, post } from '../../../utils/request'
import { GET_COMPANY } from '../business-profile/ProfileSettings'
const { showErrorToastAction, showSuccessToastAction } = require('../global')

export const SET_TAX_AND_COMPLIANCE_SETTING_ACTION = createTypes(
  'ZOOM/SETTINGS/SET_TAX_AND_COMPLIANCE',
)

const setTaxAndComplianceSettingAction = {
  do: () => createAction(SET_TAX_AND_COMPLIANCE_SETTING_ACTION.DO),
  success: (payload) => createAction(SET_TAX_AND_COMPLIANCE_SETTING_ACTION.SUCCESS, { payload }),
  failed: (errors) => createAction(SET_TAX_AND_COMPLIANCE_SETTING_ACTION.FAILED, { errors }),
}

const setTaxAndComplianceSettings = (data) => async (dispatch) => {
  try {
    const resp = await post(`user`, 'api/settings/tax-and-compliance', data)
    dispatch(showSuccessToastAction('Tax and Compliance Setting has been updated'))
    return resp?.data
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating tax & compliance setting'
    return dispatch(showErrorToastAction(error))
  }
}

const getTaxAndComplianceSettings = () => async (dispatch) => {
  try {
    dispatch(setTaxAndComplianceSettingAction.do())
    const resp = await get('user', 'api/settings/tax-and-compliance', 'GET')
    if (resp.data?.companyType) {
      dispatch(
        createAction(GET_COMPANY.SUCCESS, {
          payload: {
            ...(resp.data?.organization ?? []),
            ...resp.data?.companyType,
          },
        }),
      )
    }
    if (resp.data?.taxDetails) {
      dispatch(setTaxAndComplianceSettingAction.success(resp.data?.taxDetails))
    }
    if (
      !resp.data?.organization?.legalName ||
      resp.data?.organization?.legalName === '' ||
      !resp.data?.organization?.industry ||
      resp.data?.organization?.industry === ''
    ) {
      return {
        isSettingForFirstTime: true,
      }
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating organization setting'
    dispatch(setTaxAndComplianceSettingAction.failed(error))
  }
}

export { setTaxAndComplianceSettings, getTaxAndComplianceSettings }
