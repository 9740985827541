import 'whatwg-fetch'
import apiRoot from '../_data/apiRoot'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
const token = cookies.get('token')

async function checkStatus(response) {
  const json = await response.json()
  let error = {}
  if (!response.ok) {
    // if (response.status === 500 || response.status === 404) {
    //   window.location.href = '/'
    // }
    // error = new Error()
    // if (response.status === 401) {
    //   localStorage.removeItem('token')
    //   if (window.location.href.indexOf('login') === -1) {
    //     window.location.href = '/login'
    //   }
    // }
    if (
      !(
        response.headers.has('Content-Type') &&
        response.headers.get('Content-Type').includes('application/json')
      )
    ) {
      error.body = {
        message: 'Response is not JSON',
      }
    } else {
      if (json.message) error.body = json.message
      if (json.error) error.body = json.error
      if (json.errors) error.body = json.errors
    }
    throw error
  } else {
    return json
  }
}

export default function request(url, options) {
  return fetch(url, options).then(checkStatus)
}

export function get(module, url, method = 'GET', headers = {}) {
  let defaultHeader = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token}` : undefined,
    'X-Tenant': window.location.host.split('.')[0],
  }
  headers = Object.assign(headers, defaultHeader)

  return request(`${apiRoot[module]}${url}`, {
    method,
    headers,
  })
}
export function post(module, url, postData, method = 'POST', headers = {}) {
  let defaultHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : undefined,
    'X-Tenant': window.location.host.split('.')[0],
  }
  const newHeaders = Object.assign(defaultHeader, headers)

  return request(`${apiRoot[module]}${url}`, {
    method,
    headers: newHeaders,
    body: JSON.stringify(postData),
  })
}
export function upload(module, url, formData, headers = {}) {
  let defaultHeader = {
    Authorization: token ? `Bearer ${token}` : undefined,
    'X-Tenant': window.location.host.split('.')[0],
  }
  const newHeaders = Object.assign(defaultHeader, headers)
  return request(`${apiRoot[module]}${url}`, {
    method: 'POST',
    headers: newHeaders,
    body: formData,
  })
}
export function put(module, url, putData, headers = {}) {
  return post(module, url, putData, 'PUT', headers)
}

export function destroy(module, url, headers = {}) {
  return get(module, url, 'DELETE', headers)
}

export function download(module, url, method = 'GET', headers = {}) {
  const defaultHeader = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token}` : undefined,
    'X-Tenant': window.location.host.split('.')[0],
    // responseType: 'blob',
  }
  headers = Object.assign(defaultHeader, headers)

  return fetch(`${apiRoot[module]}${url}`, {
    method,
    headers,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const contentType = response.headers.get('Content-Type')
      if (
        contentType &&
        contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      ) {
        return response.blob() // For Excel
      }
      return response.json() // For JSON responses
    })
    .then((data) => {
      if (data instanceof Blob) {
        // Handle Excel download
        const downloadUrl = URL.createObjectURL(data)
        const downloadLink = document.createElement('a')
        downloadLink.href = downloadUrl
        downloadLink.download = 'report.xlsx'
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      } else {
        // Handle PDF download (if response is in Base64)
        const linkSource = `data:application/pdf;base64,${data.body}`
        const downloadLink = document.createElement('a')
        downloadLink.href = linkSource
        downloadLink.download = data.filename || 'report.pdf'
        downloadLink.click()
      }
    })
}

export function printPdf(module, url, method = 'GET', headers = {}) {
  let defaultHeader = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token}` : undefined,
    'X-Tenant': window.location.host.split('.')[0],
  }
  headers = Object.assign(headers, defaultHeader)

  // Return the fetch call so the promise can be awaited
  return fetch(`${apiRoot[module]}${url}`, {
    method,
    headers,
  })
    .then((response) => response.json())
    .then((response) => {
      // Ensure the server response contains a valid Base64-encoded PDF string
      if (response.body) {
        // Construct the Base64 PDF data URL
        const linkSource = `data:application/pdf;base64,${response.body}`

        const width = 800 // Popup window width
        const height = 800 // Popup window height
        const screenLeft = window.screenLeft || window.screenX
        const screenTop = window.screenTop || window.screenY
        const screenWidth = window.innerWidth || document.documentElement.clientWidth
        const screenHeight = window.innerHeight || document.documentElement.clientHeight

        const left = screenLeft + (screenWidth - width) / 2
        const top = screenTop + (screenHeight - height) / 2

        // Open a new popup window with specific dimensions
        const popupWindow = window.open(
          '',
          '_blank',
          `width=${width},height=${height},top=${top},left=${left},scrollbars=yes,resizable=yes`,
        )

        if (popupWindow) {
          // Write an iframe to load the Base64 PDF into the popup window
          popupWindow.document.write(`
            <html>
            <head><title>PDF Preview</title></head>
            <body style="margin:0">
              <iframe id="pdfIframe" src="${linkSource}" frameborder="0" style="width:100%;height:100%;"></iframe>
            </body>
            </html>
          `)
          return true // Return true on success
        }
      } else {
        throw new Error('No PDF content found')
      }
    })
    .catch((error) => {
      throw new Error(error.body ? error.body : 'Error while printing the PDF')
    })
}
