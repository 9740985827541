import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction } from '../global'
import { get, download, printPdf } from '../../../utils/request'

export const GET_PROFIT_LOSS_REPORTS = createTypes('ZOOM/TRANSACTION/GET_PROFIT_LOSS_REPORTS')

const getProfitLossReportsAction = {
  do: (params) => createAction(GET_PROFIT_LOSS_REPORTS.DO, params),
  success: (reports) => createAction(GET_PROFIT_LOSS_REPORTS.SUCCESS, { payload: reports }),
  failed: (errors) => createAction(GET_PROFIT_LOSS_REPORTS.FAILED, { payload: errors }),
}

const getProfitLoss = (params) => async (dispatch) => {
  try {
    dispatch(getProfitLossReportsAction.do())
    const resp = await get(
      'report',
      `profit-loss-report?start_date=${params.start_date}&end_date=${params.end_date}`,
      'GET',
    )
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting reports.'
    dispatch(getProfitLossReportsAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const downloadProfitLoss = (params) => async (dispatch) => {
  try {
    const resp = await printPdf(
      'report',
      `profit-loss-report-pdf?start_date=${params.start_date}&end_date=${params.end_date}`,
      'GET',
    )
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting reports.'
    return dispatch(showErrorToastAction(error))
  }
}

const downloadProfitLossExcel = (params) => async (dispatch) => {
  try {
    const resp = await download(
      'report',
      `profit-loss-report-excel?start_date=${params.start_date}&end_date=${params.end_date}`,
      'GET',
    )
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting reports.'
    return dispatch(showErrorToastAction(error))
  }
}

export { getProfitLossReportsAction, getProfitLoss, downloadProfitLoss, downloadProfitLossExcel }
