import { fromJS } from 'immutable'
import pipeMutators from '../../../utils/mutator'
import {
  CREATE_BILL,
  COPY_BILL,
  DELETE_BILL,
  GET_BILL_BY_ID,
  GET_UNPAID_BILL_BY_CONTACT_ID,
  GET_BILLS,
  UPDATE_BILL,
  INIT_SELECTED_BILL,
} from '../../actions/expense/transactionBill'

const EMPTY_BILL = {
  id: '',
  contact_id: '',
  bill_date: '',
  due_date: '',
  mailing_address: '',
  bill_no: '',
  tags: [],
  term_id: '',
  total: '',
  memo: '',
  terms: [],
  categories: [],
  items: [],
  is_recurring: false,
  addresses: {
    billing: null,
    location: null,
    shipping: null,
  },
}

const DEFAULT_BILL = {
  id: '',
  contact_id: '',
  bill_date: '',
  due_date: '',
  mailing_address: '',
  bill_no: '',
  tags: [],
  term_id: '',
  total: '',
  memo: '',
  terms: [],
  categories: [],
  items: [],
  is_recurring: false,
  addresses: {
    billing: null,
    location: null,
    shipping: null,
  },
}

const CREATE_REQUEST_BILL = {
  id: '',
  contact_id: '',
  bill_date: '',
  due_date: '',
  mailing_address: '',
  bill_no: '',
  tags: '',
  payee: '',
  term_id: '',
  total: '',
  memo: '',
  transaction_list: [],
  transaction_tag: [],
  attachment: [],
  is_recurring: false,
  addresses: {
    billing: null,
    location: null,
    shipping: null,
  },
}

const DEFAULT_STATUS = {
  errors: null,
  isSaving: false,
  isLoading: false,
  isDeleting: false,
}
const initialState = fromJS({
  status: DEFAULT_STATUS,
  bills: [],
  emptyBill: EMPTY_BILL,
  selectedBill: DEFAULT_BILL,
  createRequestBill: CREATE_REQUEST_BILL,
})

const resetStatus = () => (state) => state.mergeIn(['status'], DEFAULT_STATUS)
const setBills = (data) => (state) => state.setIn(['bills'], data)

const setEmptyBill = (data) => (state) => state.setIn(['emptyBill'], data)

const setSelectedBill = (data) => (state) => state.setIn(['selectedBill'], data)

const setCreateRequestBill = (data) => (state) => state.setIn(['createRequestBill'], data)

const resetStatusSetLoading = () => (state) =>
  resetStatus()(state).setIn(['status', 'isLoading'], true)

const resetStatusSetSaving = () => (state) =>
  resetStatus()(state).setIn(['status', 'isSaving'], true)

const resetStatusSetDeleting = () => (state) =>
  resetStatus()(state).setIn(['status', 'isDeleting'], true)

const setErrors = (errors) => (state) => resetStatus()(state).setIn(['status', 'errors'], errors)
const setUnpaidBill = (data) => (state) => state.setIn(['unpaidBill'], data)

function transactionBillReducer(state = initialState, action) {
  const mutate = pipeMutators(state)
  switch (action.type) {
    case GET_BILLS.DO:
      return mutate([resetStatusSetLoading()])
    case GET_BILLS.SUCCESS:
      return mutate([resetStatus(), setBills(action.payload)])
    case GET_BILLS.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setBills([])])
    case CREATE_BILL.DO:
      return mutate([
        resetStatusSetSaving(),
        setSelectedBill(DEFAULT_BILL),
        setEmptyBill(EMPTY_BILL),
      ])
    case CREATE_BILL.SUCCESS:
      return mutate([resetStatus()])
    case CREATE_BILL.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case COPY_BILL.DO:
      return mutate([resetStatusSetSaving()])
    case COPY_BILL.SUCCESS:
      return mutate([resetStatus()])
    case COPY_BILL.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case UPDATE_BILL.DO:
      return mutate([resetStatusSetSaving()])
    case UPDATE_BILL.SUCCESS:
      return mutate([resetStatus()])
    case UPDATE_BILL.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case DELETE_BILL.DO:
      return mutate([resetStatusSetDeleting()])
    case DELETE_BILL.SUCCESS:
      return mutate([resetStatus()])
    case DELETE_BILL.FAILED:
      return mutate([resetStatus(), setErrors(action.payload)])
    case GET_BILL_BY_ID.DO:
      return mutate([resetStatusSetLoading()])
    case GET_BILL_BY_ID.SUCCESS:
      return mutate([resetStatus(), setSelectedBill(action.payload)])
    case GET_BILL_BY_ID.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setSelectedBill(DEFAULT_BILL)])
    case GET_UNPAID_BILL_BY_CONTACT_ID.DO:
      return mutate([resetStatusSetLoading()])
    case GET_UNPAID_BILL_BY_CONTACT_ID.SUCCESS:
      return mutate([resetStatus(), setUnpaidBill(action.payload)])
    case GET_UNPAID_BILL_BY_CONTACT_ID.FAILED:
      return mutate([resetStatus(), setErrors(action.payload), setUnpaidBill([])])
    case INIT_SELECTED_BILL:
      return mutate([
        resetStatus(),
        setCreateRequestBill(CREATE_REQUEST_BILL),
        setSelectedBill(DEFAULT_BILL),
        setEmptyBill(EMPTY_BILL),
      ])
    default:
      return state
  }
}

export default transactionBillReducer
