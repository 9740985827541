import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { post, get, put, destroy } from '../../../utils/request'

export const CREATE_CREDIT = createTypes('ZOOM/BANKING/CREATE_CREDIT')
export const GET_CREDIT = createTypes('ZOOM/BANKING/GET_CREDIT')
export const UPDATE_CREDIT = createTypes('ZOOM/BANKING/UPDATE_CREDIT')
export const DELETE_CREDIT = createTypes('ZOOM/BANKING/DELETE_CREDIT')

const createCreditAction = {
  do: (params) => createAction(CREATE_CREDIT.DO, params),
  success: () => createAction(CREATE_CREDIT.SUCCESS),
  failed: (errors) => createAction(CREATE_CREDIT.FAILED, { errors }),
}

const getCreditAction = {
  do: (params) => createAction(GET_CREDIT.DO, params),
  success: (credit) => createAction(GET_CREDIT.SUCCESS, { payload: credit }),
  failed: (errors) => createAction(GET_CREDIT.FAILED, { errors }),
}

const updateCreditAction = {
  do: (params) => createAction(UPDATE_CREDIT.DO, params),
  success: (credit) => createAction(UPDATE_CREDIT.SUCCESS, { payload: credit }),
  failed: (errors) => createAction(UPDATE_CREDIT.FAILED, { errors }),
}

const deleteCreditAction = {
  do: () => createAction(DELETE_CREDIT.DO),
  success: () => createAction(DELETE_CREDIT.SUCCESS),
  failed: (errors) => createAction(DELETE_CREDIT.FAILED, { errors }),
}

const getCreditById = (id) => async (dispatch) => {
  try {
    dispatch(getCreditAction.do())
    if (id) {
      const resp = await get('banking', `credit-card-credits/${id}`, 'GET')
      var creditData = resp
      creditData.categories = resp.transaction.tran_list
      creditData.lines = resp.transaction.tran_item
      creditData.tags = resp.transaction.tran_tag
      creditData.receive_payment = resp.receive_payment
      let addresses = {}
      resp.addresses.forEach((address) => {
        addresses[address.type] = address
      })
      dispatch(getCreditAction.success({ ...creditData, addresses: addresses }))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting credit data.'
    dispatch(getCreditAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const updateCredit = (id, params) => async (dispatch) => {
  try {
    dispatch(updateCreditAction.do())
    const resp = await put('banking', `credit-card-credits/${id}`, params)
    dispatch(showSuccessToastAction('Successfully updated.'))
    dispatch(updateCreditAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating credit data.'
    dispatch(updateCreditAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const createCredit = (params) => async (dispatch) => {
  try {
    dispatch(createCreditAction.do())
    await post('banking', 'credit-card-credits', params)
    dispatch(createCreditAction.success())
    dispatch(showSuccessToastAction('Successfully created.'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating bank credit.'
    dispatch(createCreditAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const deleteCredit = (id) => async (dispatch) => {
  try {
    await destroy('banking', `credit-card-credits/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    return true
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while deleting the data.'
    dispatch(showErrorToastAction(error))
    return false
  }
}

const generateCreditCardCreditNumber = async () => async (dispatch) => {
  try {
    const resp = await get('expense', 'expense/generate-credit-card-credit-number', 'GET')
    return resp
  } catch (e) {
    const error = e.body
      ? e.body
      : 'An error occurred while getting latest credit card credit number.'
    dispatch(showErrorToastAction(error))
  }
}

export {
  createCreditAction,
  createCredit,
  getCreditAction,
  getCreditById,
  updateCreditAction,
  updateCredit,
  deleteCreditAction,
  deleteCredit,
  generateCreditCardCreditNumber,
}
